//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import threeCanvas from '@/plugins/components/threeCanvas/index.vue';

// 有效的模型命名集合
var availableMeshs = [
// mname： 模型命名
// name 显示命名
// labels： 在label中展示的数据列表
{ mname: 'egwater', name: '水源' },
{ mname: 'egcontrol', name: '控制柜' },
{ mname: 'egstpressurepump', name: '稳压泵' },
{
  mname: 'egmainpump',
  name: '消防主泵',
  labels: [
  { name: '系统计算流量', key: 'qj' },
  { name: '系统设计流量', key: 'qs' },
  { name: '泵设计压力', key: 'h' }] },


{
  mname: 'egsafety',
  name: '安全阀' },

{ mname: 'egpiezometer', name: '压力表' },
{
  mname: 'egmainpipe',
  name: '消防总管',
  labels: [
  { name: '总管内径', key: 'di' },
  { name: '基本计算壁厚', key: 't0' },
  { name: '设计壁厚', key: 't' }] },


{ mname: 'egzonecontrolvalve', name: '分区控制阀' },
{
  mname: 'egbranchpipe',
  name: '支管',
  labels: [{ name: '支管内径', key: 'oneQ' }] },

{
  mname: 'egzozzle',
  name: '喷头',
  labels: [
  { name: '喷头数量', key: 'n' },
  { name: '单个喷头流量', key: 'q' }] }];




export default {
  components: { threeCanvas: threeCanvas },
  data: function data() {
    return {
      // 模块名字
      meshName: 'demo',
      availableMeshs: availableMeshs,
      objModel: {
        // 模型 放在 public 静态文件夹
        path: '/model/',
        mtlPath: 'ballast water_01.mtl',
        objPath: 'ballast water_01.obj' } };


  } };