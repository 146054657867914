var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("threeCanvas", {
        ref: "threeCanvas",
        attrs: {
          objModel: _vm.objModel,
          meshName: _vm.meshName,
          availableMeshs: _vm.availableMeshs
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }